import type { RouteRecordRaw } from 'vue-router'

const QrcodeMarketing: RouteRecordRaw = {
  path: '/qrcode',
  component: () => import('@/layouts/index.vue'),
  meta: {
    title: '渠道码',
    icon: 'ant-design:qrcode-outlined',
    breadcrumb: true,
  },
  children: [
    {
      path: 'channel',
      name: 'ChannelCode',
      redirect: { name: 'ChannelCodeList' },
      meta: {
        title: '渠道码管理',
        icon: 'ant-design:qrcode-outlined',
        breadcrumb: true,
      },
      children: [
        {
          path: 'list',
          name: 'ChannelCodeList',
          component: () => import('@/views/crm/qrcode/channel/list.vue'),
          meta: {
            title: '渠道码列表',
            icon: 'ant-design:unordered-list-outlined',
            breadcrumb: true,
            cache: true,
            sidebar: false,
          },
        },
        {
          path: 'create',
          name: 'ChannelCodeCreate',
          component: () => import('@/views/crm/qrcode/channel/detail.vue'),
          meta: {
            title: '新增渠道码',
            icon: 'ant-design:plus-outlined',
            sidebar: false,
            breadcrumb: true,
          },
        },
        {
          path: ':id/edit',
          name: 'ChannelCodeEdit',
          component: () => import('@/views/crm/qrcode/channel/detail.vue'),
          meta: {
            title: '编辑渠道码',
            icon: 'ant-design:edit-outlined',
            sidebar: false,
            breadcrumb: true,
          },
        },
      ],
    },
    {
      path: 'config',
      name: 'ChannelConfig',
      redirect: { name: 'ChannelConfigList' },
      meta: {
        title: '渠道配置',
        icon: 'ant-design:setting-outlined',
        breadcrumb: true,
      },
      children: [
        {
          path: 'list',
          name: 'ChannelConfigList',
          component: () => import('@/views/crm/qrcode/channel-config/list.vue'),
          meta: {
            title: '渠道配置列表',
            icon: 'ant-design:unordered-list-outlined',
            breadcrumb: true,
            cache: true,
            sidebar: false,
          },
        },
        {
          path: 'create',
          name: 'ChannelConfigCreate',
          component: () => import('@/views/crm/qrcode/channel-config/detail.vue'),
          meta: {
            title: '新增渠道配置',
            icon: 'ant-design:plus-outlined',
            sidebar: false,
            breadcrumb: true,
          },
        },
        {
          path: ':id/edit',
          name: 'ChannelConfigEdit',
          component: () => import('@/views/crm/qrcode/channel-config/detail.vue'),
          meta: {
            title: '编辑渠道配置',
            icon: 'ant-design:edit-outlined',
            sidebar: false,
            breadcrumb: true,
          },
        },
      ],
    },
    {
      path: 'analysis/new-customer',
      name: 'AnalysisNewCustomer',
      component: () => import('@/views/crm/wework/analysis/new-customer.vue'),
      meta: {
        title: '新客分析',
        icon: 'ant-design:dashboard-outlined',
        breadcrumb: true,
        cache: true,
        sidebar: true,
      },
    },
  ],
}

export default QrcodeMarketing
