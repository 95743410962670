import type { RouteRecordRaw } from 'vue-router'

const OperationCrm: RouteRecordRaw = {
  path: '/operation',
  component: () => import('@/layouts/index.vue'),
  meta: {
    title: '智能运营',
    icon: 'game-icons:smart',
    breadcrumb: true,
  },
  children: [
    {
      path: 'result-task',
      name: 'CrowdTaskIndex',
      redirect: { name: 'CrowdTaskList' },
      meta: {
        title: '人群计划',
        icon: 'mynaui:users-group',
        breadcrumb: true,
      },
      children: [
        {
          path: 'list',
          name: 'CrowdTaskList',
          component: () => import('@/views/crm/operation/crowd-task/list.vue'),
          meta: {
            title: '人群计划列表',
            breadcrumb: true,
            sidebar: false,
            cache: true,
          },
        },
        {
          path: 'detail',
          name: 'CrowdTaskDetail',
          component: () => import('@/views/crm/operation/crowd-task/detail.vue'),
          meta: {
            title: '人群计划详情',
            breadcrumb: true,
            sidebar: false,
            cache: true,
          },
        },
        {
          path: 'create',
          name: 'CrowdTaskCreate',
          component: () => import('@/views/crm/operation/crowd-task/detail.vue'),
          meta: {
            title: '创建人群计划',
            breadcrumb: true,
            sidebar: false,
          },
        },
        {
          path: 'edit',
          name: 'CrowdTaskEdit',
          component: () => import('@/views/crm/operation/crowd-task/detail.vue'),
          meta: {
            title: '编辑人群计划',
            breadcrumb: true,
            sidebar: false,
          },
        },
        {
          path: 'result',
          name: 'CrowdTaskResult',
          component: () => import('@/views/crm/operation/crowd-task/result/index.vue'),
          meta: {
            title: '人群计划结果',
            breadcrumb: true,
            sidebar: false,
            cache: true,
          },
        },
      ],
    },
    {
      path: 'auto-tag-task',
      name: 'AutoTagTaskIndex',
      redirect() {
        return { name: 'AutoTagTaskList' }
      },
      meta: {
        title: '自动标签',
        icon: 'fluent-mdl2:hexadite-investigation-semi-auto',
        breadcrumb: true,
      },
      children: [
        {
          path: 'list',
          name: 'AutoTagTaskList',
          component: () => import('@/views/crm/operation/auto-tag/list.vue'),
          meta: {
            title: '自动标签列表',
            breadcrumb: true,
            sidebar: false,
            cache: true,
          },
        },
        {
          path: 'detail',
          name: 'AutoTagTaskDetail',
          component: () => import('@/views/crm/operation/auto-tag/detail.vue'),
          meta: {
            title: '自动标签详情',
            breadcrumb: true,
            sidebar: false,
            cache: true,
          },
        },
        {
          path: 'create',
          name: 'AutoTagTaskCreate',
          component: () => import('@/views/crm/operation/auto-tag/detail.vue'),
          meta: {
            title: '创建自动标签',
            breadcrumb: true,
            sidebar: false,
          },
        },
        {
          path: 'edit',
          name: 'AutoTagTaskEdit',
          component: () => import('@/views/crm/operation/auto-tag/detail.vue'),
          meta: {
            title: '编辑自动标签',
            breadcrumb: true,
            sidebar: false,
          },
        },
      ],
    },
  ],
}

export default OperationCrm
