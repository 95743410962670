import { setupLayouts } from 'virtual:meta-layouts'
import generatedRoutes from 'virtual:generated-pages'
import type { RouteRecordRaw } from 'vue-router'
import type { Route } from '#/global'
import useSettingsStore from '@/store/modules/settings'
import CustomerCrm from '@/router/modules/customer.crm.ts'
import TagCrm from '@/router/modules/tag.crm.ts'
import InsightsCrm from '@/router/modules/insights.crm.ts'
import OperationCrm from '@/router/modules/operation.crm.ts'
import Distribution from '@/router/modules/distribution.ts'
import AnalysisCrm from '@/router/modules/analysis.crm.ts'
import QrcodeMarketing from '@/router/modules/qrcode.marketing.ts'
import Inheritance from '@/router/modules/inheritance.crm'

// 固定路由（默认路由）
const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      whiteList: true,
      title: '登录',
      i18n: 'route.login',
    },
  },
  {
    path: '/auth/sso/callback',
    name: 'ssoCallback',
    component: () => import('@/views/auth/sso_callback.vue'),
    meta: {
      whiteList: true,
      title: 'SSO 登录回调',
    },
  },
  {
    path: '/:all(.*)*',
    name: 'notFound',
    component: () => import('@/views/[...all].vue'),
    meta: {
      title: '找不到页面',
    },
  },
]

// 系统路由
const systemRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layouts/index.vue'),
    meta: {
      title: () => useSettingsStore().settings.home.title,
      breadcrumb: false,
    },
    children: [
      {
        path: '',
        component: () => import('@/views/index.vue'),
        meta: {
          title: () => useSettingsStore().settings.home.title,
          i18n: 'route.home',
          icon: 'ant-design:home-twotone',
          breadcrumb: false,
        },
      },
      {
        path: 'reload',
        name: 'reload',
        component: () => import('@/views/reload.vue'),
        meta: {
          title: '重新加载',
          breadcrumb: false,
        },
      },
      {
        path: 'personal/notification',
        name: 'personalNotification',
        component: () => import('@/views/personal/notification.vue'),
        meta: {
          title: '通知中心',
          i18n: 'route.personal.notification',
        },
      },
    ],
  },
]

// 动态路由（异步路由、导航栏路由）
const asyncRoutes: Route.recordMainRaw[] = [
  {
    meta: {
      title: 'CRM',
      i18n: 'route.demo',
      icon: 'uim:box',
    },
    children: [
      AnalysisCrm,
      CustomerCrm,
      TagCrm,
      InsightsCrm,
      OperationCrm,
      QrcodeMarketing,
      Inheritance,
    ],
  },
  {
    meta: {
      title: '导购助手',
      i18n: 'route.distribution',
      icon: 'ant-design:home-twotone',
    },
    children: Distribution,
  },
]

const constantRoutesByFilesystem = generatedRoutes.filter((item) => {
  return item.meta?.enabled !== false && item.meta?.constant === true
})

const asyncRoutesByFilesystem = setupLayouts(generatedRoutes.filter((item) => {
  return item.meta?.enabled !== false && item.meta?.constant !== true && item.meta?.layout !== false
}))

export {
  constantRoutes,
  systemRoutes,
  asyncRoutes,
  constantRoutesByFilesystem,
  asyncRoutesByFilesystem,
}
