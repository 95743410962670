import type { RouteRecordRaw } from 'vue-router'

const Transfer: RouteRecordRaw = {
  path: '/inheritance',
  component: () => import('@/layouts/index.vue'),
  meta: {
    title: '在职继承',
    icon: 'ant-design:api-outlined',
    breadcrumb: true,
    sidebar: true,
  },
  children: [
    {
      path: 'create',
      name: 'InheritanceCreate',
      // redirect: { name: 'InheritanceCreate' },
      meta: {
        title: '在职继承',
        icon: 'ant-design:unordered-list-outlined',
        breadcrumb: true,
        cache: true,
      },
      component: () => import('@/views/inheritance/create/index.vue'),
      children: [],
    },
    {
      path: 'list',
      name: 'InheritanceTasks',
      component: () => import('@/views/inheritance/tasks/index.vue'),
      meta: {
        title: '继承任务',
        icon: 'ant-design:unordered-list-outlined',
        breadcrumb: true,
        cache: true,
      },
    },
    {
      path: 'detail/:id/:no',
      name: 'InheritanceTaskDetail',
      component: () => import('@/views/inheritance/detail/index.vue'),
      meta: {
        title: '继承任务详情',
        icon: 'ant-design:unordered-list-outlined',
        breadcrumb: true,
        sidebar: false,
        cache: true,
      },
    },
  ],
}

export default Transfer
