import axios from 'axios'

import Message from 'vue-m-message'
import useSettingsStore from '@/store/modules/settings'
import useUserStore from '@/store/modules/user'

const api = axios.create({
  baseURL: (import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true') ? '/proxy/' : import.meta.env.VITE_APP_API_BASEURL,
  timeout: 1000 * 60,
  responseType: 'json',
  // 注意这是适配 gozero 的编码器, 勿用于其他
  paramsSerializer: (params) => {
    return Object.keys(params).map((key) => {
      if (params[key] === undefined) {
        return `${encodeURIComponent(key)}=`
      }
      if (Array.isArray(params[key])) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(params[key]))}`
      }
      else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      }
    }).join('&')
  },
})
api.interceptors.request.use(
  (request) => {
    // 全局拦截请求发送前提交的参数
    const settingsStore = useSettingsStore()
    const userStore = useUserStore()
    // 设置请求头
    if (request.headers) {
      request.headers['Accept-Language'] = settingsStore.lang
      if (userStore.isLogin) {
        request.headers.Authorization = `Bearer ${userStore.token}`
      }
    }
    return request
  },
)

api.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data)
  },
  (error) => {
    let message = ''
    if (error.response) {
      switch (error.response.status) {
        case 400:
          message = '请求错误'
          break
        case 401:
          message = '未授权，请登录'
          useUserStore().logout()
          window.location.reload()
          break
        case 403:
          message = '拒绝访问'
          break
        case 404:
          message = `请求地址出错: ${error.response.config.url}`
          break
        case 408:
          message = '请求超时'
          break
        case 500:
          message = '服务器内部错误'
          break
        case 501:
          message = '服务未实现'
          break
        case 502:
          message = '网关错误'
          break
        case 503:
          message = '服务不可用'
          break
        case 504:
          message = '网关超时'
          break
        case 505:
          message = 'HTTP版本不受支持'
          break
        default:
          message = `连接错误${error.response.status}`
      }
    }
    else {
      message = '连接到服务器失败'
    }

    // 排除请求被取消
    if (error.code !== 'ERR_CANCELED') {
      Message.error(`请求错误: ${message}`, {
        zIndex: 2000,
      })
    }

    return Promise.reject(error)
  },
)

export default api
